import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import Props from 'components/Props';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=Select wrap=function",
        "componentName": "Select",
        "wrap": "function"
      }}>{`() => {
  const options = ['Bananaaa', 'Lime', 'Coconut', 'Mango'];
  const [value, setValue] = React.useState('');

  return (
    <Select
      id="fruit"
      value={value}
      placeholder="What's your favourite fruit?"
      onChange={e => setValue(e.target.value)}
      label="About fruit"
      message="Jummy!"
    >
      {options.map(option => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </Select>
  );
};
`}</code></pre>
    <h2 {...{
      "id": "props",
      "style": {
        "position": "relative"
      }
    }}>{`Props`}</h2>
    <Props identifier="Select" mdxType="Props" />
    <h2 {...{
      "id": "action",
      "style": {
        "position": "relative"
      }
    }}>{`Action`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`Adds an action or extra information to your item, mostly used for links.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=function",
        "wrap": "function"
      }}>{`() => {
  const options = ['One', 'two', 'three', 'aaand', 'action'];
  const [value, setValue] = React.useState('');

  return (
    <Select
      id="action"
      value={value}
      onChange={e => setValue(e.target.value)}
      label="Label"
      action={<LinkText href="#">LinkText</LinkText>}
    >
      {options.map(option => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </Select>
  );
};
`}</code></pre>
    <h2 {...{
      "id": "disabled",
      "style": {
        "position": "relative"
      }
    }}>{`Disabled`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`Marking a Select as `}<inlineCode parentName="p">{`disabled`}</inlineCode>{`, will disable all interaction with the element.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=function",
        "wrap": "function"
      }}>{`() => {
  const options = ['Nope', 'not', 'possible'];
  const [value, setValue] = React.useState('');

  return (
    <Select
      id="disabled"
      value={value}
      onChange={e => setValue(e.target.value)}
      label="Label"
      disabled
    >
      {options.map(option => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </Select>
  );
};
`}</code></pre>
    <h2 {...{
      "id": "error",
      "style": {
        "position": "relative"
      }
    }}>{`Error`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`To convey an error to the user, you can set the `}<inlineCode parentName="p">{`error`}</inlineCode>{` prop to true.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=function",
        "wrap": "function"
      }}>{`() => {
  const options = ['Whoops', "Thath's", 'a', 'fail'];
  const [value, setValue] = React.useState('');

  return (
    <Select
      id="error"
      value={value}
      onChange={e => setValue(e.target.value)}
      label="Label"
      error
    >
      {options.map(option => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </Select>
  );
};
`}</code></pre>
    <h2 {...{
      "id": "iconleft",
      "style": {
        "position": "relative"
      }
    }}>{`IconLeft`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`For further illustration purposes, you can provide an `}<inlineCode parentName="p">{`iconLeft`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=function",
        "wrap": "function"
      }}>{`() => {
  const options = ['Hot', 'And', 'Trending'];
  const [value, setValue] = React.useState('');

  return (
    <Select
      id="iconLeft"
      value={value}
      onChange={e => setValue(e.target.value)}
      label="Label"
      iconLeft={TrendingFill}
    >
      {options.map(option => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </Select>
  );
};
`}</code></pre>
    <h2 {...{
      "id": "labelhidden",
      "style": {
        "position": "relative"
      }
    }}>{`LabelHidden`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`To meet accessibility requirements, every Select should have an associated
label. We do support the ability to visually hide the label when the Select's
purpose is clear.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=function",
        "wrap": "function"
      }}>{`() => {
  const options = ['Always', 'Add', 'Label'];
  const [value, setValue] = React.useState('');

  return (
    <Select
      id="labelHidden"
      value={value}
      onChange={e => setValue(e.target.value)}
      label="Label"
      labelHidden
    >
      {options.map(option => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </Select>
  );
};
`}</code></pre>
    <h2 {...{
      "id": "message",
      "style": {
        "position": "relative"
      }
    }}>{`Message`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`Give additional information to the user.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=function",
        "wrap": "function"
      }}>{`() => {
  const options = ['A', 'bit', 'more', 'context'];
  const [value, setValue] = React.useState('');

  return (
    <Select
      id="message"
      value={value}
      onChange={e => setValue(e.target.value)}
      label="Label"
      message="Message can be set to provide additional information."
    >
      {options.map(option => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </Select>
  );
};
`}</code></pre>
    <h2 {...{
      "id": "optionallabel-required-fields",
      "style": {
        "position": "relative"
      }
    }}>{`OptionalLabel (Required fields)`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`By default the Select is always `}<strong parentName="p">{`required`}</strong>{`. To make a Slect optional you can
add the `}<inlineCode parentName="p">{`optionalLabel`}</inlineCode>{` prop.`}</p>
    <p>{`With Chameleon we take a specific approach where we encourage marking optional
fields, not required. The majority of fields in a form are always required.
Indicating that fields are required make users more fearful, it increases the
risk of errors and reduces the form completion rate.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=function",
        "wrap": "function"
      }}>{`() => {
  const options = ['Not', 'so', 'required'];
  const [value, setValue] = React.useState('');

  return (
    <Select
      id="optionalLabel"
      value={value}
      onChange={e => setValue(e.target.value)}
      label="Label"
      optionalLabel="I'm optional"
    >
      {options.map(option => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </Select>
  );
};
`}</code></pre>
    <h2 {...{
      "id": "placeholder",
      "style": {
        "position": "relative"
      }
    }}>{`Placeholder`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`Placeholder text is displayed inside the field but is not always necessary. They
should be written as examples helping users to understand the required input
instead of instructions.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=function",
        "wrap": "function"
      }}>{`() => {
  const options = ['Nope', 'not', 'a', 'default'];
  const [value, setValue] = React.useState('');

  return (
    <Select
      id="placeholder"
      value={value}
      placeholder="Make a selection..."
      onChange={e => setValue(e.target.value)}
      label="Label"
    >
      {options.map(option => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </Select>
  );
};
`}</code></pre>
    <h2 {...{
      "id": "required",
      "style": {
        "position": "relative"
      }
    }}>{`Required`}</h2>
    <p><inlineCode parentName="p">{`default: true`}</inlineCode></p>
    <p>{`By default the Select is required. You can change this by providing a
optionalLabel or passing false to the required prop. We encourage you to use the
optionalLabel prop, this provides additional feedback to the user!`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=function",
        "wrap": "function"
      }}>{`() => {
  const options = ['Secretly', 'not', 'required'];
  const [value, setValue] = React.useState('');

  return (
    <Select
      id="required"
      value={value}
      onChange={e => setValue(e.target.value)}
      label="Label"
      required={false}
    >
      {options.map(option => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </Select>
  );
};
`}</code></pre>
    <h2 {...{
      "id": "value",
      "style": {
        "position": "relative"
      }
    }}>{`Value`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`Use value for controlled Selects.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=function",
        "wrap": "function"
      }}>{`() => {
  const options = ['Select', 'a', 'value'];
  const [value, setValue] = React.useState('');

  return (
    <Select
      id="value"
      value={value}
      onChange={e => setValue(e.target.value)}
      label="Label"
    >
      {options.map(option => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </Select>
  );
};
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      